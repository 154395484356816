import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import { colors, viewportWidth } from '../styles/config';
import { spacing } from '../styles/utils/spacing';

import Logo from '../components/Logo';
import Nav, { NavLink } from '../components/Nav';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: ${viewportWidth};
  margin: ${spacing(4)} auto;
  border-bottom: 0.1rem solid ${colors.gray.base};
`;

const Header = () => {
  return (
    <Wrapper>
      <Link to="/">
        <Logo marginTop={4} marginBottom={4} />
      </Link>

      <Nav>
        <NavLink to="/">Home</NavLink>
        <NavLink to="/destinations">Destinationen</NavLink>
      </Nav>
    </Wrapper>
  );
};

export default Header;
