import round from 'lodash/round';
import { spacingConfig } from '../config';

export function spacing(multiplier, config = spacingConfig) {
  if (!multiplier) {
    return '0';
  }

  if (typeof multiplier === 'number') {
    return `${round(config.base * multiplier, 8)}${config.unit}`;
  }

  return multiplier;
}
