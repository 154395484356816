import { css } from 'styled-components';

export default css`
  *,
  ::before,
  ::after {
    box-sizing: border-box;
    background-repeat: no-repeat;
  }

  html {
    min-height: 100%;

    /* Prevent font size adjustments after orientation changes in IE and iOS. */
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
  }

  body,
  div,
  span,
  iframe,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  a,
  abbr,
  acronym,
  address,
  big,
  cite,
  code,
  del,
  dfn,
  em,
  figure,
  img,
  ins,
  kbd,
  q,
  s,
  samp,
  small,
  strike,
  strong,
  sub,
  sup,
  tt,
  var,
  dl,
  dt,
  dd,
  ol,
  ul,
  li,
  fieldset,
  form,
  label,
  legend,
  button,
  input,
  select,
  textarea,
  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td {
    margin: 0;
    padding: 0;
    border: 0;
    font-weight: inherit;
    font-style: inherit;
    font-family: inherit;
    font-size: 100%;
    line-height: inherit;
    vertical-align: baseline;
  }

  ol,
  ul {
    list-style: none;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  caption,
  th,
  td {
    text-align: left;
    font-weight: normal;
  }

  article,
  aside,
  canvas,
  details,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  menu,
  nav,
  section,
  summary,
  main {
    display: block;
  }

  hr {
    /* Add the correct box sizing in Firefox. */
    box-sizing: content-box;
    height: 0;

    /* Show the overflow in Edge and IE. */
    overflow: visible;
  }

  a {
    text-decoration: none;
    color: inherit;

    /* Remove the gray background on active links in IE 10. */
    background-color: transparent;

    /*  Remove gaps in links underline in iOS 8+ and Safari 8+. */
    -webkit-text-decoration-skip: objects;
  }

  /* Remove the tapping delay on clickable elements. */
  a,
  area,
  button,
  input,
  label,
  select,
  summary,
  textarea,
  [tabindex] {
    -ms-touch-action: manipulation; /* IE 10 */
    touch-action: manipulation;
  }

  audio,
  canvas,
  iframe,
  img,
  svg,
  video {
    vertical-align: middle;
  }

  canvas {
    display: inline-block;
  }

  /* Add the correct display in iOS 4-7. */
  audio:not([controls]) {
    display: none;
    height: 0;
  }

  img {
    /* Keep images from scaling beyond the width of their parents. */
    max-width: 100%;

    /* Prevent images from being stretched when width or height attributes are present. */
    height: auto;

    /* Remove the border on images inside anchors in IE 10-. */
    border: 0;
  }

  /* Hide overflow in IE. */
  svg:not(:root) {
    overflow: hidden;
  }

  /* Reset from control colors */
  button,
  input,
  textarea {
    color: inherit;
    background-color: transparent;
  }

  /* Show overflow */
  button, /* IE */
  input { /* Edge */
    overflow: visible;
  }

  /* Remove the inheritance of text transform */
  button, /* Edge, Firefox, IE */
  select { /* Firefox */
    text-transform: none;
  }

  /* Remove the inner border and padding in Firefox. */
  button::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0;
  }

  /* Restore the focus styles unset by the previous rule. */
  button:-moz-focusring,
  [type="button"]:-moz-focusring,
  [type="reset"]:-moz-focusring,
  [type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText;
  }

  progress {
    display: inline-block; /* IE 9- */
    vertical-align: baseline; /* Chrome, Firefox, Opera */
  }

  textarea {
    /* Remove the default vertical scrollbar in IE. */
    overflow: auto;

    /* Change the resize direction on textareas in all browsers */
    resize: vertical;
  }

  [type="search"] {
    /* Correct the odd appearance in Chrome and Safari. */
    -webkit-appearance: textfield;

    /* Correct the outline style in Safari. */
    outline-offset: -2px;
  }

  /* Remove the inner padding and cancel buttons in Chrome and Safari on macOS. */
  [type="search"]::-webkit-search-cancel-button,
  [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
  }

  ::-webkit-file-upload-button {
    /* Correct the inability to style clickable types in iOS and Safari. */
    -webkit-appearance: button;

    /* Change font properties to 'inherit' in Safari. */
    font: inherit;
  }

  /* Correct display in IE 10- */
  [hidden] {
    display: none;
  }

  /* Best matching cursor for disabled state */
  [aria-disabled],
  [disabled] {
    cursor: not-allowed;
  }

  /* Dropzone use aria-disabled="false" so enable default cursor for falsy values */
  [aria-disabled="false"] {
    cursor: default;
  }

  /* Best matching cursor for loading state */
  [aria-busy="true"] {
    cursor: progress;
  }
`;
