import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import { viewportWidth, font, fontSizes } from '../styles/config';
import { spacing } from '../styles/utils/spacing';

const StyledLink = styled(Link)`
  color: #fff;
  font-family: ${font.accent};
  font-weight: normal;

  &:hover {
    color: #fff;
  }
`

const Wrapper = styled.footer`
  max-width: ${viewportWidth};
  margin: ${spacing(6)} auto;
  text-align: center;
  font-size: ${fontSizes.sm};

  > * {
    display: inline-block;
    margin-right: ${spacing(1)};

    &:last-child {
      margin-right: 0;
    }
  }
`;

const Footer = () => {
  return (
    <Wrapper>
      <StyledLink to="/imprint">Impressum</StyledLink>
      <StyledLink to="/privacy">Datenschutz</StyledLink>
    </Wrapper>
  );
};

export default Footer;
