import React from 'react';
import styled from 'styled-components';

import { font } from '../styles/config';
import { margins } from '../styles/utils/margins';

const StyledText = styled.span`
  ${margins}

  font-family: ${font.accent};
  letter-spacing: 0.2rem;
  font-size: 2rem;

  ${({ level }) => level === 1 && `
    font-size: 4rem;
  `}

  ${({ level }) => level === 2 && `
    font-size: 3rem;
  `}

  ${({ level }) => level === 3 && `
    font-size: 2.5rem;
  `}
`;

const StyledHeading = {
  h1: StyledText.withComponent('h1'),
  h2: StyledText.withComponent('h2'),
  h3: StyledText.withComponent('h3'),
  h4: StyledText.withComponent('h4'),
};

const Heading = (props) => {
  const {
    children,
    level = 2,
    ...rest
  } = props;

  const HeadingElement = StyledHeading[`h${level}`];

  return (
    <HeadingElement level={level} {...rest}>
      {children}
    </HeadingElement>
  );
};

Heading.defaultProps = {};

export default Heading;
