import { injectGlobal } from 'styled-components';

import reset from './reset';
import { font, rootFontSize, fontSizes, colors, transitionDuration } from '../config';

import bg from '../../assets/bg.png';

injectGlobal`
  ${reset}

  *,
  ::before,
  ::after {
    transition-duration: ${transitionDuration};
    transition-property: none;
  }

  html,
  body,
  #root {
    height: 100%;
  }

  html {
    font-size: ${rootFontSize};
    line-height: 1;
    color: ${colors.text};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: ${font.default};
    background-image: url(${bg});
    background-repeat: repeat;
  }

  body {
    font-size: ${fontSizes.md};
  }

  button,
  [role=button] {
    cursor: pointer;
  }

  /* Globally remove focus styles if a mouse/touch pointer was detected by „what-input“. */
  [data-whatinput="mouse"],
  [data-whatinput="touch"] {
    a:focus,
    button:focus,
    [role=button]:focus,
    input:focus,
    textarea:focus {
      outline: 0;
    }
  }

  em {
    font-style: italic;
  }

  a {
    color: ${colors.primary.base};
    font-weight: bold;
    transition-property: color;

    &:hover {
      color: ${colors.primary.dark};
    }
  }

  .tab-community {
    display: none;
  }
`;
