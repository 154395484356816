import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';

import '../styles/global';
import { spacing } from '../styles/utils/spacing';

import Header from '../components/Header';
import Footer from '../components/Footer';
import { viewportWidth } from '../styles/config';

const Wrapper = styled.div`
  padding: ${spacing(1)};
  padding-bottom: ${spacing(6)};
  background-color: #EEEEEE;
`;

const Content = styled.div`
  max-width: ${viewportWidth};
  margin: 0 auto;
`;

const Layout = (props) => {
  const { children } = props;

  return (
    <StaticQuery
      query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
      render={data => (
        <>
          <Helmet
            title={data.site.siteMetadata.title}
            meta={[
              { name: 'description', content: 'Sample' },
              { name: 'keywords', content: 'sample, something' },
            ]}
          />
          <Wrapper>
            <Header />

            <Content>
              {children}
            </Content>
          </Wrapper>

          <Footer />
        </>
      )}
    />
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
