import styled, { css } from 'styled-components';

import { margins } from '../styles/utils/margins';
import { fontSizes, lineHeights } from '../styles/config';

const styles = (props) => {
  const {
    size = 'sm',
    lineHeight,
    color,
    align,
    bold,
    upper,
    breakWord,
    autoSlug,
    truncate,
  } = props;

  const lineHeightValue = lineHeight ? lineHeights[lineHeight] : lineHeights[size];

  return css`
    font-size: ${fontSizes[size]};
    line-height: ${lineHeightValue};

    ${breakWord && `
      word-wrap: break-word;
    `}

    ${truncate && `
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `}

    ${autoSlug && `
      &:not(:last-child) {
        margin-bottom: ${lineHeightValue};
      }
    `}

    ${color && `
      color: ${color};
    `}

    ${align && `
      text-align: ${align};
    `}

    ${bold && `
      font-weight: bold;
    `}

    ${upper && `
      text-transform: uppercase;
      letter-spacing: 0.2rem;
    `}

    strong {
      font-weight: bold;
    }
  `;
};

export const StyledText = styled.p`
  ${margins}
  ${styles}
`;

StyledText.defaultProps = {
  size: 'sm',
};

export default StyledText;
