import styled from 'styled-components';

import { spacing } from '../styles/utils/spacing';

const PostsMatrix = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: ${spacing(2)};
  grid-row-gap: ${spacing(2)};

  @media (max-width: 620px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export default PostsMatrix;
