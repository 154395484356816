import React from 'react';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';

import { colors } from '../styles/config';
import { spacing } from '../styles/utils/spacing';

import Heading from '../components/Heading';
import Text from '../components/Text';
import Flag from '../components/Flag';

const StyledLink = styled(Link)`
  display: block;
  color: ${colors.text};
  font-weight: normal;
  background: #fff;
`;

const Content = styled.div`
  padding: ${spacing(2)};
`;

const ImageHolder = styled.div`
  position: relative;
`;

const FlagHolder = styled.div`
  position: absolute;
  top: ${spacing(1)};
  right: ${spacing(1)};
`

const getImage = (postPath, images) => {
  const path = postPath.substr(0, postPath.lastIndexOf('/'));

  const imageNode = images.edges.find(({ node }) => node.relativePath.startsWith(path));

  if (!imageNode) {
    return null;
  }

  return imageNode.node;
};

const PostPreview = (props) => {
  const { node, images } = props;

  return (
    <StyledLink to={node.fields.slug}>
      <ImageHolder>
        {getImage(node.relativePath, images) && (
          <Img fluid={getImage(node.relativePath, images).childImageSharp.fluid} />
        )}

        <FlagHolder>
          <Flag country={node.frontmatter.destination} width="2.5rem" />
        </FlagHolder>
      </ImageHolder>

      <Content>
        <Heading level={3} marginBottom={2}>{node.frontmatter.title}</Heading>

        <Text lineHeight="lg">{node.frontmatter.subtitle}</Text>
      </Content>
    </StyledLink>
  );
};

export default PostPreview;
