import React from 'react';
import styled from 'styled-components';

import { margins } from '../styles/utils/margins';

import logo from '../assets/logo-text.svg';

const Image = styled.img`
  ${margins}
  display: block;
  max-width: 30rem;
  width: 100%;
`

const Logo = (props) => {
  return (
    <Image src={logo} {...props} />
  );
};

export default Logo;
