import React from 'react';
import styled from 'styled-components';

import flagEs from './flags/es.svg';
import flagCa from './flags/ca.svg';

const flagMap = {
  es: flagEs,
  ca: flagCa,
};

const Image = styled.img`
  display: block;

  ${({ width }) => `
    width: ${width};
  `}
`;

const Flag = (props) => {
  const { country, width = '2rem' } = props;
  const flagSrc = flagMap[country];

  if (!flagSrc) {
    return null;
  }

  return (
    <Image src={flagSrc} width={width} />
  );
};

export default Flag;
