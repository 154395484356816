import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

import { colors, font, fontSizes } from '../styles/config';
import { spacing } from '../styles/utils/spacing';

const StyledLink = styled(Link)`
  font-family: ${font.accent};
  font-size: ${fontSizes.xl};
  font-weight: normal;
  color: ${colors.text};
  margin-right: ${spacing(3)};

  &:last-child {
    margin-right: 0;
  }

  &.active {
    font-weight: 600;
  }
`;

const Wrapper = styled.nav`
  margin-bottom: ${spacing(2)};
`

export const NavLink = (props) => {
  return (
    <StyledLink activeClassName="active" {...props} />
  );
};

const Nav = (props) => {
  const { children } = props;

  return (
    <Wrapper>
      {children}
    </Wrapper>
  )
};

export default Nav;
