import { darken } from 'polished';

export const font = {
  default: '\'Open Sans\', sans-serif',
  accent: '\'Special Elite\', sans-serif',
};

export const rootFontSize = '62.5%';
export const fontSizes = {
  xs: '1.4rem',
  sm: '1.6rem',
  md: '1.8rem',
  lg: '2.0rem',
  xl: '2.4rem',
  xxl: '3.4rem',
};

export const lineHeights = {
  xs: '1.6rem',
  sm: '1.6rem',
  md: '2.4rem',
  lg: '2.4rem',
  xl: '2.4rem',
  xxl: '4rem',
};

export const colors = {
  text: '#666666',
  primary: {
    base: '#E78B90',
    dark: darken(.1, '#E78B90'),
  },
  gray: {
    base: '#8F8D8E',
    light: '#C4C4C4',
  },
};

export const viewportWidth = '70rem';

export const spacingConfig = {
  base: 0.8,
  unit: 'rem',
};

export const transitionDuration = '0.3s';
export const borderRadius = '0.3rem';

export const zIndices = {
  overlay: 10,
};
