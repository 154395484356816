import { directionalProperty } from 'polished';

import { spacing } from './spacing';


function spacingIfDefined(multiplier) {
  return multiplier ? spacing(multiplier) : null;
}

export function margins(props) {
  const all = spacingIfDefined(props.margin);
  const y = spacingIfDefined(props.marginY);
  const x = spacingIfDefined(props.marginX);
  const top = spacingIfDefined(props.marginTop);
  const right = spacingIfDefined(props.marginRight);
  const bottom = spacingIfDefined(props.marginBottom);
  const left = spacingIfDefined(props.marginLeft);

  return {
    ...directionalProperty(
      'margin',
      top || y || all || null,
      right || x || all || null,
      bottom || y || all || null,
      left || x || all || null,
    ),
  };
}
